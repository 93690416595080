* {
  outline: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

svg {
  /** Fix large viewBox and translates in IE */
  overflow: hidden;
}

#__next {
  display: flex;
}

html, body {
  width: 100%;
  min-width: 320px;
}

input, textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none !important;
}

input::-ms-reveal {
  display: none !important;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

main {
  display: block;
}

ul,
ol,
nav {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}

a:hover, a:focus {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
}

/*body {*/
/*  overflow-y: auto;*/
/*  scrollbar-width: thin;*/
/*  scrollbar-color: #F9683A transparent;*/
/*}*/

/*body::-webkit-scrollbar {*/
/*  width: 0.375rem;*/
/*}*/

/*body::-webkit-scrollbar-track {*/
/*  background-color: #eee;*/
/*}*/

/*body::-webkit-scrollbar-thumb {*/
/*  background-color: rgba(170, 170, 170, 0.6);*/
/*}*/

/*body::-webkit-scrollbar-thumb:hover {*/
/*  background-color: rgba(153, 153, 153, 0.6);*/
/*}*/

body {
  font-family: 'Raleway', 'Inter', 'NeueMachina',
  -apple-system,
  'BlickMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;
  font-size: 14px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Стрелка-тоггрер на панели предпросмотра сайта в админке  */
button[type="button"] > svg > path[d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"] {
  fill: #000 !important;
}
