@font-face {
  font-family: 'Raleway';
  src: url('/static/fonts/Raleway/Raleway-Regular.woff2') format('woff2'), url('/static/fonts/Raleway/Raleway-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/static/fonts/Raleway/Raleway-Medium.woff2') format('woff2'), url('/static/fonts/Raleway/Raleway-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/static/fonts/Raleway/Raleway-SemiBold.woff2') format('woff2'), url('/static/fonts/Raleway/Raleway-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('/static/fonts/Raleway/Raleway-Bold.woff2') format('woff2'), url('/static/fonts/Raleway/Raleway-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/static/fonts/Inter/Inter-Regular.woff2') format('woff2'), url('/static/fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NeueMachina';
  src: url('/static/fonts/NeueMachina/NeueMachina-Bold.woff2') format('woff2'), url('/static/fonts/NeueMachina/NeueMachina-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
